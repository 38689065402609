//=require /jquery.bxslider.js
//=require /../../node_modules/wowjs/dist/wow.js

// jQuery.fn.extend({
//     onAppearanceAddClass: function(class_to_add) {
//       var $window = $( window ),
//           window_height = $window.height(),
//           array_of_$elements = [];
//       this.each(function(i,el) {
//         array_of_$elements.push($( el ));
//       })
//       scrollHandler();
//           if (array_of_$elements.length) {
//         $window.on('resize', resizeHandler).on('resize', scrollHandler).on('scroll', scrollHandler);
//       }
//       function resizeHandler() {
//         window_height = $window.height();
//       }
//       function watchProcessedElements(array_of_indexes) {
//           var l, i;
//         for (l = array_of_indexes.length, i = l - 1; i > -1; --i) {
//           array_of_$elements.splice(array_of_indexes[i], 1);
//         }
//         if (!array_of_$elements.length) {
//           $window.off('resize', resizeHandler).off('scroll', scrollHandler).off('resize', scrollHandler);
//         }
//       }
//       function scrollHandler() {
//         var i, l, processed = [];
//         for ( l = array_of_$elements.length, i = 0; i < l; ++i ) {
//           if ($window.scrollTop() + window_height > array_of_$elements[i].offset().top) {
//             array_of_$elements[i].addClass(class_to_add);
//             processed.push(i); 
//           }
//         }
//         if (processed.length) {
//           watchProcessedElements(processed);
//         }
//       }
//       return this;
//     }
//   })


  function animateCSS(node, animationName, callback) {
    //const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName)
        node.removeEventListener('animationend', handleAnimationEnd)

        if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
}



  



var AppMaps = [];
var AppSliders = [];

function initMaps(){


          $('.component-map').each(function () {

            var lat = this.dataset.mapLat;
            var lng = this.dataset.mapLng;
            var label = this.dataset.mapLabel;

            var content = $(this).find('.component-content')[0];

            var myMap;

            $(content).css('height', '400px');
            $(content).html('');

            console.log('Создание карты:', lat, lng, label, content);

              // Создание карты.    
              myMap = new ymaps.Map(content, {
                  // Координаты центра карты.
                  // Порядок по умолчанию: «широта, долгота».
                  // Чтобы не определять координаты центра карты вручную,
                  // воспользуйтесь инструментом Определение координат.
                  center: [ lat, lng ],
                  // Уровень масштабирования. Допустимые значения:
                  // от 0 (весь мир) до 19.
                  zoom: 16,
                 // controls: ['smallMapDefaultSet']
              });

              myMap.behaviors.disable('scrollZoom');

              // Вспомогательный класс, который можно использовать
              // вместо GeoObject c типом геометрии «Point» (см. предыдущий пример)
              var myPlacemark = new ymaps.Placemark(
                [ lat, lng ], 
                { iconCaption: label }, 
                { preset: 'islands#blueDotIconWithCaption' }
              );

              myMap.geoObjects.add(myPlacemark);
 
      
              AppMaps.push(myMap);
              
               
              // myMap.geoObjects.add( new ymaps.Placemark(
              //   myMap.getCenter(), 
              //   {
              //       iconCaption: label
              //   }, 
              //   {
              //       preset: 'islands#greenDotIconWithCaption'
              //   }));
           
             
          

          
        
        });
}


$(document).ready(function () {

    console.log("Объектная модель готова к использованию!");

    // $(window).scroll(function(){
    //     var wt = $(window).scrollTop();
    //     var wh = $(window).height();
    //     var et = $('элемент').offset().top;
    //     var eh = $('элемент').outerHeight();
    //     var dh = $(document).height();   
    //     if (wt + wh >= et || wh + wt == dh || eh + et < wh){
    //         console.log('Элемент показан');
    //     }
    // });


    // $('.animation').each(function () {

    //     var animation = this.dataset.animation;

    //     console.log('Начало анимации', animation)
    //     // or
    //     animateCSS(this, animation, function() {
    //         // Do something after animation
    //         console.log('Конец анимации', animation)
    //     })
    // });

    var wow = new WOW(
        {
          boxClass:     'wow',      // animated element css class (default is wow)
          animateClass: 'animated', // animation css class (default is animated)
          offset:       10,          // distance to the element when triggering the animation (default is 0)
          mobile:       true,       // trigger animations on mobile devices (default is true)
          live:         true,       // act on asynchronously loaded content (default is true)
          callback:     function(box) {
            // the callback is fired every time an animation is started
            // the argument that is passed in is the DOM node being animated
          },
          scrollContainer: null // optional scroll container selector, otherwise use window
        }
      );
      wow.init();

    // Функция ymaps.ready() будет вызвана, когда
    // загрузятся все компоненты API, а также когда будет готово DOM-дерево.
    ymaps.ready(initMaps);

    $('.js-form').on("click", ".js-form-submit", function (e) {

        e.preventDefault();

        var form = $(this.form);

        var csrfParam = $('meta[name="csrf-param"]').attr("content");
        var csrfToken = $('meta[name="csrf-token"]').attr("content");

        var data = form.serialize();

        $.ajax({

            url: '/order/send',
            type: 'POST',
            data: data + '&_csrf=' + csrfToken ,

        }).done(function(data) {
            if(data.success) {

                // данные сохранены
                console.log('Данные сохранены');
                // очищает форму
                $(form)[0].reset();

                // ym(51922136, 'reachGoal', 'BTN_ORDER');

                if(typeof (ym) === "function" && setting.yandexMetrika){
                    ym(setting.yandexMetrika, 'reachGoal', 'BTN_ORDER');        
                 }else{
                     console.log('goal', 'Ошибка отправки цели!', 'BTN_ORDER');
                 }

                // редирект
                if(data.redirect){
                    window.location.href = data.url ? data.url : "/order/index";
                }

            } else {
                // сервер вернул ошибку и не сохранил наши данные
                if(data.errors){

                    var text = '';

                    for (var key in data.errors) {
                        if (data.errors.hasOwnProperty(key)) {
                            text = text +  data.errors[key].toString();
                            text = text + '<br>';
                        }
                    }

                    $(form).find('.component-form-error').html('<p class="form-error">' + text +'</p>').addClass('visible');

                }
                // console.log(data)
            }
        })
            .fail(function () {
                // не удалось выполнить запрос к серверу
            });

        return false;

        console.log('submit');

    });


    $('[data-goal]').click(function (e) {
  
        console.log('goal', setting.yandexMetrika, this.dataset.goal);
        
        if(this.dataset.goal && typeof (ym) === "function" && setting.yandexMetrika){
           ym(setting.yandexMetrika, 'reachGoal', this.dataset.goal);        
        }else{
            console.log('goal', 'Ошибка отправки цели!');
        }

    })

    
        $('.component-menu > .component-menu-content > ul > li > a').each(function () {

           $url = encodeURI($(this).attr('href'));
            console.log("Меню", $(this).attr('href'), location.href, location.pathname );
          if($url == location.href || $url == location.pathname) $(this).addClass('active');
       
        });

       
    
        console.log("bxSlider");
        AppSliders = $('.bxslider').bxSlider({
          auto: true,
          autoControls: true,
          stopAutoOnClick: true,
          pager: true,
          prevText: '',
          nextText: '',
          //slideWidth: 600
        });


          
      
         
        



});






